<template>
  <Modal
    :open.sync="modalOpen"
    @hide="onHide"
    :title="title"
    :footer="false"
    backdrop="static"
    :canClose="!busy"
  >
    <template #content>
      <ImportFileForm
        :connectorId="connectorId"
        :deviceId="deviceId"
        :dataId="dataId"
        :entity="entity"
        :reset="reset"
        :hasBasic="hasBasic"
        :accept="accept"
        :downloadBaseFileFunction="downloadBaseFileFunction"
        @submit="submitFileForm"
      />
    </template>
  </Modal>
</template>
<script>
import ImportFileForm from "@/components/import-file-form.vue";
import Modal from "@/components/widgets/modal.vue";
import ConnectorService from "@/services/connector.js";
import DeviceService from "@/services/device.js";
import DataService from "@/services/data.js";
import AlarmService from "@/services/alarm.js";
import ReasonService from "@/services/reason.js";
import CasualtyService from "@/services/casualty.js";
import MixinAlert from "@/project/mixin-alert.js";

export default {
  name: "ModalImportFile",
  components: {
    ImportFileForm,
    Modal
  },
  mixins: [MixinAlert],
  props: {
    entity: {
      type: String,
      required: true
    },
    connectorId: {
      type: Number
    },
    deviceId: {
      type: Number
    },
    dataId: {
      type: Number
    },
    hasBasic: {
      type: Boolean,
      default: () => false
    },
    accept: {
      type: String,
      default: () => '.csv'
    }
  },
  data() {
    return {
      busy: false,
      title: null,
      service: null,
      baseFileName: null,
      reset: false,
      modalOpen: false,
      response: null
    };
  },
  watch: {
    entity(n, o) {
      if (n == "") return;

      this.busy = false;
      this.reset = false;
      this.$nextTick(() => {
        this.reset = true;
      });

      switch (this.entity) {
        case "connector":
          this.title = this.$t("import_connectors");
          this.service = new ConnectorService();
          this.baseFileName = "connectors-form-full.csv";
          this.modalOpen = true;
          break;
        case "device":
          this.title = this.$t("import_devices");
          this.service = new DeviceService();
          this.baseFileName = "connector-devices-form-full.csv";
          this.modalOpen = true;
          break;
        case "data":
          this.title = this.$t("import_data");
          this.service = new DataService();
          this.baseFileName = "connector-data-form-full.csv";
          this.modalOpen = true;
          break;
        case "alarm":
          this.title = this.$t("import_alarms");
          this.service = new AlarmService();
          this.baseFileName = "connector-alarms-form-full.csv";
          this.modalOpen = true;
          break;
        case "reason":
          this.title = this.$t("import_reason");
          this.service = new ReasonService();
          this.baseFileName = "reasons-form-full.csv";
          this.modalOpen = true;
          break;
        case "casualty":
          this.title = this.$t("import_casualty");
          this.service = new CasualtyService();
          this.baseFileName = "casualties-form-full.csv";
          this.modalOpen = true;
          break;
        default:
          break;
      }
    }
  },
  methods: {
    downloadBaseFileFunction(base_file) {
      base_file = base_file || "true";
      let file_name = this.baseFileName;
      if (base_file == "basic") {
        file_name = file_name.replaceAll('form-full.','form-basic.');
      }
      this.service.export('base_file='+base_file, file_name);
    },
    submitFileForm(formData) {
      let self = this;
      this.busy = true;
      this.service
        .import(formData)
        .then((response) => {
          // Caso seja importação de Conector e um arquivo HPC, recarregar
          // toda a hierarquia importada. Também pode ter atualizado
          // algumas coisas no "portal_data" do Contrato, então é feito uma
          // requisição para atualização
          if (
            this.entity == "connector" && 
            Array.from(formData.entries())
              .find(entry => entry[0] == "file")[1]
              .name.endsWith('.hpc')
          ) {
            (response || []).forEach((connector) =>
              this.$store.dispatch("dashboard/fetchConnector",
              {id: connector.id, deep: true})
            );

            this.$store.dispatch("user/configureUserContract");
          }

          self.busy = false;
          self.reset = false;
          self.$nextTick(() => {
            self.reset = true;
          });
          self.modalOpen = false;
          self.response = response;
        })
        .catch((error) => {
          self.busy = false;
          self.reset = false;
          self.$nextTick(() => {
            self.reset = true;
          });
          if (typeof error == "string") {
            self.alert = {
              title: self.$t("error"),
              text: error,
              type: "error"
            };
          } else {
            self.alert = {
              title: self.$t("error"),
              text: self.$t("unknown_error"),
              type: "error"
            };
          }
          self.showAlert();
          self.modalOpen = false;
          self.response = null;
        });
    },
    onHide(event) {
      if (this.response != null)
        this.$emit("resource_imported", this.response, this.entity);
      this.$nextTick(() => this.$emit("close"));
    }
  }
};
</script>
